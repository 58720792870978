import React, { useState, useEffect } from "react"
import Layout from "../components/layout/layout"
import Header from "../components/header/header"
import { Form, Input, FormGroup, Button, Alert } from "reactstrap"
import { fetch_post } from "../utils/fetch"
import { toast } from "react-toastify"
import { Link } from "gatsby"
import "../styles/forgot.scss"
import { Loading } from "../components/loading"
import { useAuth0 } from "@auth0/auth0-react"
import { checkPassword } from "../utils/common"

export default function ForgotPassword() {
  const { isLoading, isAuthenticated, user } = useAuth0()
  const [email, setEmail] = useState("")
  const [status, setStatus] = useState(false)
  const [loading, setLoading] = useState(false)
  const [inputCode, setInputCode] = useState("")
  const [markerCode, setMarkerCode] = useState("")
  const [formInvalid, setFormInvalid] = useState(true)
  const [inputInvalid, setInputInvalid] = useState("")

  //Verification Code
  const createCode = () => {
    var canvasBtn = document.getElementById("mycanvas")
    var context = canvasBtn.getContext("2d")
    var codeNum = ""
    var codeLength = 4
    var random = [
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "O",
      "P",
      "Q",
      "R",
      "S",
      "T",
      "U",
      "V",
      "W",
      "X",
      "Y",
      "Z",
    ]
    canvasBtn.width = 70
    canvasBtn.height = 36
    for (var i = 0; i < codeLength; i++) {
      var index = Math.floor(Math.random() * 36)
      codeNum += random[index]
    }
    const result = codeNum.toLowerCase()
    setMarkerCode(result)
    context.font = "20px Lato"
    context.fillStyle = "#495057"
    context.textAlign = "center"
    context.fillText(result, 35, 25)

    //Draw interference lines
    for (var l = 0; l <= 5; l++) {
      context.strokeStyle = randomColor()
      context.beginPath()
      context.moveTo(Math.random() * 70, Math.random() * 36)
      context.lineTo(Math.random() * 70, Math.random() * 36)
      context.stroke()
    }

    //Draw interference points
    for (var p = 0; p <= 30; p++) {
      context.strokeStyle = randomColor()
      context.beginPath()
      var x = Math.random() * 70
      var y = Math.random() * 36
      context.moveTo(x, y)
      context.lineTo(x + 1, y + 1)
      context.stroke()
    }
  }

  // Random color
  const randomColor = () => {
    var r = Math.floor(Math.random() * 256)
    var g = Math.floor(Math.random() * 256)
    var b = Math.floor(Math.random() * 256)
    return "rgb(" + r + "," + g + "," + b + ")"
  }

  useEffect(() => {
    if (!isLoading) {
      if (markerCode === "") {
        createCode()
      }
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading])

  useEffect(() => {
    // Check whether it can be committed
    if (inputCode.length <= 0) {
      setInputInvalid("success")
    } else if (String(inputCode) !== String(markerCode)) {
      setInputInvalid("error")
    } else {
      setInputInvalid("success")
    }

    if (String(inputCode) === String(markerCode) && email !== "") {
      setFormInvalid(false)
    } else {
      setFormInvalid(true)
    }
  }, [inputCode, email, markerCode])

  if (isLoading) {
    return <Loading />
  }

  const handleSubmit = event => {
    event.preventDefault()
    if (checkPassword(isAuthenticated, user)) return;
    setLoading(true)
    fetch_post(
      `users/forgot_password`,
      {
        email: email,
      },
      null
    )
      .then(() => {
        setStatus(true)
      })
      .catch(error => {
        toast(`${error}`, {
          type: "error",
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <Layout pageTitle="forgot password">
      <Header
        backgroundImage="/dashborad_header.png"
        className="fullPageHeader mb-0 p-0 position-fixed w-100"
        contentPage={true}
      ></Header>
      <div className="forgotDiv">
        {status ? (
          <div className="forgotForm">
            <div className="successDiv">
              <Alert color="success">
                Your external email will receive a reset password link in a few minutes.
              </Alert>
              <Link to="/">
                <Button className="btnBgColor">Back Home</Button>
              </Link>
            </div>
          </div>
        ) : (
          <Form className="forgotForm">
            <div className="formContent">
              <FormGroup>
                <Input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Input your CodeLinaro email address."
                  value={email}
                  onChange={event => setEmail(event.target.value)}
                />
              </FormGroup>
              <div className="verifyDiv">
                <div className="markerCodeBtn">
                  <canvas id="mycanvas" onClick={createCode}></canvas>
                </div>
                <FormGroup>
                  <Input
                    type="text"
                    name="inputCode"
                    id="inputCode"
                    value={inputCode}
                    placeholder="Input captcha."
                    onChange={event => setInputCode(event.target.value)}
                  />
                </FormGroup>
              </div>
              <p className="title">{inputInvalid === "success" ? "" : "Wrong input"}</p>

              <Button
                className="mr-2 btnBgColor"
                color={"primary"}
                onClick={handleSubmit}
                disabled={formInvalid}
              >
                {loading ? (
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  >
                    <span className="sr-only">Sending...</span>
                  </span>
                ) : (
                  "Confirm"
                )}
              </Button>
            </div>
          </Form>
        )}
      </div>
    </Layout>
  )
}
